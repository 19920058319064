import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import styled from 'styled-components'
import logoDark from '../../assets/images/logo6.png'
import logoLight from '../../assets/images/logo-light.png'
// import { Nav } from 'src/data/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from 'src/store/Auth/thunk'
import _ from 'lodash'
import { toast } from 'react-toastify'

const Header = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const lastUrl = `/${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)}`
    const { user } = useSelector((state) => state.Login)

    const [isActive, setIsActive] = useState(false)
    const handleClick = () => {
        // 👇️ toggle isActive state on click
        setIsActive((current) => !current)
    }
    useEffect(() => {
        setIsActive(false)
    }, [location])

    const onLogout = async () => {
        try {
            const body = {
                refreshToken: localStorage.getItem('userRefreshToken')
            }
            const res = await dispatch(userLogout(body)).unwrap()
            if (res) {
                // setTimeout(() => {
                navigate('/club-membership')
                // dispatch(userLoad())
                // }, 1000)
                // setTimeout(() => {
                //
                // })
                //     toast.success(res)
            }
        } catch (err) {
            if (Array.isArray(err) && err.length) {
                const msg = _.get(_.first(err), 'msg')
                toast.error(msg)
            } else if (err) {
                toast.error(err)
            }
        }
    }
    return (
        <StyledHeader
            className='header-main header-easy header-dark fixed-top'
            style={{ opacity: '1 ' }}
            id='page-topbar'
        >
            <div className='navbar navbar-expand-lg navbar-dark'>
                <Container fluid className='px-lg-5 px-md-3'>
                    <Link className='navbar-brand' to='/club-membership'>
                        <img className='logo-dark img-responsive' src={logoDark} title='' alt='' />
                        <img
                            className='logo-light img-responsive'
                            src={logoLight}
                            title=''
                            alt=''
                        />
                    </Link>
                    <button
                        className='navbar-toggler'
                        onClick={handleClick}
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='navbar-toggler-icon' />
                    </button>

                    <div
                        className={
                            isActive ? 'show collapse navbar-collapse' : 'collapse navbar-collapse'
                        }
                        id='navbarSupportedContent'
                    >
                        <ul className='navbar-nav ms-auto align-items-center'>
                            {/* {Array.isArray(Nav)
                                ? Nav.map((nav, index) => (
                                      <li className='nav-item' key={index}>
                                          <Link
                                              to={nav.url}
                                              className={`nav-link ${
                                                  lastUrl === nav.url ? 'active' : ''
                                              }`}
                                          >
                                              {nav?.name || ''}{' '}
                                          </Link>
                                      </li>
                                  ))
                                : null} */}
                            {user?.id ? (
                                <li className='nav-item'>
                                    <a
                                        href='https://www.easy2show.com/assets/pdf/Easy2Show_Club_Management_HOW_2.pdf'
                                        target='_blank'
                                        className='nav-link'
                                        rel='noreferrer'
                                    >
                                        How 2{' '}
                                    </a>
                                </li>
                            ) : null}
                            <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                <Link
                                    className={`nav-link btn-secondary btn-sm ${
                                        lastUrl === '/club-membership' ? 'active' : ''
                                    }`}
                                    to='/club-membership'
                                >
                                    Club Memberships
                                </Link>
                            </li>
                            <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                <Link
                                    className={`nav-link btn-secondary btn-sm ${
                                        lastUrl === '/sanctions' ? 'active' : ''
                                    }`}
                                    to='/sanctions'
                                >
                                    Sanctions
                                </Link>
                            </li>
                            {/* <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                <Link 
                                    className={`nav-link btn-secondary btn-sm ${
                                        lastUrl === '/membership' ? 'active' : ''
                                      }`}
                                     to='/membership'>
                                  Membership
                                </Link>
                            </li> */}
                            {user?.user_role !== 'user' ? (
                                <>
                                    {/* <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                        <Link
                                            className='nav-link btn-secondary btn-sm'
                                            to='/member-register'
                                        >
                                            User Registration
                                        </Link>
                                    </li>
                                    <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                        <Link
                                            className='nav-link btn-secondary btn-sm'
                                            to='/club-register'
                                        >
                                            Club Registration
                                        </Link>
                                    </li> */}
                                    <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                        <Link
                                            className={`nav-link btn-secondary btn-sm ${
                                                lastUrl === '/plans' ? 'active' : ''
                                            }`}
                                            to='/plans'
                                        >
                                            Club Plans
                                        </Link>
                                    </li>

                                    <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                        <DropdownButton className='cust-drop' title='Registration'>
                                            <Dropdown.Item>
                                                <Link to='/member-register'>
                                                    Member Registration
                                                </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Link to='/club-register'>Club Registration</Link>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </li>

                                    <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                        <Link
                                            className={`nav-link btn-secondary btn-sm ${
                                                lastUrl === '/login' ? 'active' : ''
                                            }`}
                                            to='/login'
                                        >
                                            Login
                                        </Link>
                                    </li>
                                    <li className='nav-item dropdown res-text-c prof-btn temo-m-l'>
                                        <Link
                                            className={`nav-link btn-secondary btn-sm ${
                                                lastUrl === '/clerk-login' ? 'active' : ''
                                            }`}
                                            to='/clerk-login'
                                        >
                                            Clerk Login
                                        </Link>
                                    </li>
                                </>
                            ) : (
                                <li className='nav-item res-text-c prof-btn m-0 res-dis-none'>
                                    <i
                                        className='fa fa-user nav-link fz-22px prof-icn'
                                        aria-hidden='true'
                                    />
                                </li>
                            )}
                            {user?.id && (
                                <li className='nav-item dropdown res-text-c prof-btn ml-0'>
                                    <DropdownButton
                                        className='cust-drop user-icon u-font'
                                        title={_.truncate(
                                            `${user?.last_name || ''} ${user?.first_name || ''}`,
                                            {
                                                length: 18,
                                                omission: '...'
                                            }
                                        )}
                                        // title={`${_.size(`${user?.last_name || ''} ${user?.first_name || ''}`) >15 ? } `}
                                    >
                                        <Dropdown.Item>
                                            <Link to='/profile'>Profile</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to='/sub-user'>Sub Members</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to='/purchased_membership'>
                                                Purchased Membership
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to='/newsletter'>Newsletter</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to='/purchased_sanctions'>
                                                Purchased Sanctions
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to='/change-password'>Change Password</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={onLogout}>
                                            <Link to='/'>Logout</Link>
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </li>
                            )}
                        </ul>
                    </div>
                </Container>
            </div>
        </StyledHeader>
    )
}

export default Header

const StyledHeader = styled.div`
    left: 0px !important;
`
