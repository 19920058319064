import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

const Footer = () => (
    <StyledHeader className='footer1 footer-page'>
        <Container fluid>
            <Row>
                <Col sm={6} className='text-left'>
                    {' '}
                    Copyright © {new Date().getFullYear()} Club Management. All rights reserved.
                </Col>
                <Col sm={4} className='text-left'>
                    <a href='/terms_conditions' className='text-white'>Website Terms and Conditions</a>
                </Col>
                <Col sm={2} className='text-right'>
                    <a href='mailto:support@easy2show.com' className='text-white'>Contact Us</a>
                </Col>
            </Row>
        </Container>
    </StyledHeader>
)

export default Footer
const StyledHeader = styled.footer`
    left: 0px !important;
`