import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

//
const ClubRegister = lazy(() => import('src/pages/ClubRegister/ClubRegister'))
//
const ClubLogin = lazy(() => import('src/pages/Login/Login'))
const ForgotPassword = lazy(() => import('src/pages/ForgotPassword/ForgotPassword'))
const VerifyAccountClub = lazy(() => import('src/pages/VerifyAccountClub/VerifyAccountClub'))
const VerifyAccountMember = lazy(() => import('src/pages/VerifyAccount/VerifyAccountMember'))
const Plans = lazy(() => import('src/pages/Plans/Index'))
//
const UserRegister = lazy(() => import('src/pages/UserRegister/UserRegister'))
const ChangePassword = lazy(() => import('src/pages/ChangePassword/ChangePassword'))
const Profile = lazy(() => import('src/pages/Profile/Profile'))

// Sub User
const SubUser = lazy(() => import('src/pages/SubUser/SubUser'))
const SubUserForm = lazy(() => import('src/pages/SubUser/Form'))

// Membership
const Membership = lazy(() => import('src/pages/Membership/Index'))
const ClubMembership = lazy(() => import('src/pages/ClubMembership'))
const Payment = lazy(() => import('src/pages/Payment/Index'))
const PaymentProcess = lazy(() => import('src/pages/ClubMembership/PaymentProcess'))

// Sanctions
const Sanctions = lazy(() => import('src/pages/Sanctions/Index'))
const PlanDetail = lazy(() => import('src/pages/Sanctions/PlanDetailForm'))
const Checkout = lazy(() => import('src/pages/Sanctions/SanctionCheckout'))
const SanctionPaymentProcess = lazy(() => import('src/pages/Sanctions/PaymentProcess'))

// PurchasedMembershipList
const PurchasedMembershipList = lazy(() => import('src/pages/PurchasedMembership'))
const ClubNewsLetter = lazy(() => import('src/pages/ClubNewsLetter'))

// PurchasedSanctionsList
const PurchasedSanctionsList = lazy(() => import('src/pages/PurchasedSanctions/Index'))

const ClerkLogin = lazy(() => import('src/pages/ClerkLogin/ClerkLogin'))

const TermsConditions = lazy(() => import('src/pages/Terms/Index'))

const MembershipListWithPurchasedPlan = lazy(
    () => import('src/pages/PurchasedMembership/PlanListByMember/PlanListByMember')
)
//
const userRoutes = [
    {
        path: '/sub-user',
        component: <SubUser />,
        name: 'Sub User'
    },
    {
        path: '/sub-user/create',
        component: <SubUserForm />,
        name: 'Sub User Create'
    },
    {
        path: '/sub-user/update/:id',
        component: <SubUserForm />,
        name: 'Sub User Update'
    },
    {
        path: '/change-password',
        component: <ChangePassword />,
        name: 'Change Password'
    },
    {
        path: '/profile',
        component: <Profile />,
        name: 'Profile'
    },
    {
        path: '/purchased_membership',
        component: <PurchasedMembershipList />,
        name: 'Profile'
    },
    {
        path: '/purchased_sanctions',
        component: <PurchasedSanctionsList />,
        name: 'Purchased Sanctions'
    },
    {
        path: '/purchased_membership/:userId/purchased-plans',
        component: <MembershipListWithPurchasedPlan />,
        name: 'Membership List'
    },

    {
        path: '/newsletter',
        component: <ClubNewsLetter />,
        name: 'Club Newsletter'
    },
    {
        path: '/terms_conditions',
        component: <TermsConditions />,
        name: 'Website Terms and Conditions'
    },
]

const PublicRoute = [
    {
        path: '/club-register',
        component: <ClubRegister />,
        name: 'Club Register'
    },
    {
        path: '/member-register',
        component: <UserRegister />,
        name: 'User Register'
    },

    {
        path: '/login',
        component: <ClubLogin />,
        name: 'Club Login'
    },
    {
        path: '/forgot-password',
        component: <ForgotPassword />,
        name: 'Forgot Password'
    },
    {
        path: '/verify-account',
        component: <VerifyAccountMember />,
        name: 'Verify Account Club'
    },

    // Sub User

    {
        path: '/verify-account-club',
        component: <VerifyAccountClub />,
        name: 'Verify Account Club'
    },
    {
        path: '/plans',
        component: <Plans />,
        name: 'Plans'
    },
    {
        path: '/membership',
        component: <Membership />,
        name: 'Membership'
    },
    {
        path: '/club-membership',
        component: <ClubMembership />,
        name: 'Membership'
    },
    {
        path: '/club-membership/payment',
        component: <Payment />,
        name: 'Payment'
    },
    {
        path: '/club-membership/payment/process-payment',
        component: <PaymentProcess />,
        name: 'Payment'
    },

    // Sanctions
    {
        path: '/sanctions',
        component: <Sanctions />,
        name: 'Sanctions'
    },
    {
        path: '/sanctions/plan',
        component: <PlanDetail />,
        name: 'Sanctions'
    },

    {
        path: '/sanctions/plan/checkout',
        component: <Checkout />,
        name: 'Sanction Checkout'
    },
    {
        path: '/sanctions/plan/checkout/process-payment',
        component: <SanctionPaymentProcess />,
        name: 'Sanction Payment'
    },
    {
        path: '/clerk-login',
        component: <ClerkLogin />,
        name: 'Clerk Login'
    },

    {
        path: '/terms_conditions',
        component: <TermsConditions />,
        name: 'Website Terms and Conditions'
    },
  
    {
        path: '*',
        component: <Navigate to='/club-membership' />
    }
]

export { userRoutes, PublicRoute }